<template>
    <div class="d-flex justify-content-center w-100">
        <main>
            <div class="container text-center">
                <img class="w-75" alt="Be-housing logo" src="@/assets/img/logo.png"/>
                <h1 class="fw-bold mb-3">BE-HOUSING</h1>
                <form class="form-group bg-primary text-start p-4 mb-3" @submit.prevent="onSubmit()">
                    <label class="w-100 mb-3">
                        <span class="text-light h5">Username</span>
                        <input class="form-control" v-model="username" type="text"/>
                    </label>
                    <label class="w-100 mb-4">
                        <span class="text-light h5">Password</span>
                        <input class="form-control" v-model="password" type="password"/>
                    </label>
                    <el-button native-type="submit"
                               type="info"
                               class="w-100 font-large-5 fw-bold"
                               :loading="userRequestInProgress"
                               @click="onSubmit()">
                        LOGIN
                    </el-button>
                </form>
                <div v-if="loginError" class="alert-danger p-1">{{ loginError.message }}</div>
            </div>
        </main>
    </div>
</template>

<script lang="ts">
import Vue from "@/extensions/Vue";
import {Options} from "vue-class-component";
import CreateTokenRequest from "@/http/requests/create-token-request";
import {initializeStore, userContext} from "@/store";
import UserGetters from "@/store/modules/user/user-getters";
import UserState from "@/store/modules/user/user-state";

@Options({
    name: "Login",
})
export default class Login extends Vue {
    login = userContext.actions.login;

    username = "";
    password = "";

    get isLoggedIn(): UserGetters["hasSession"] {
        return userContext.getters.hasSession;
    }

    get loginError(): UserState["loginError"] {
        return userContext.state.loginError;
    }

    get userRequestInProgress(): boolean {
        return userContext.state.requestInProgress;
    }

    async onSubmit(): Promise<void> {
        const createTokenRequest = new CreateTokenRequest(this.username, this.password);

        await this.login(createTokenRequest);

        if (!this.isLoggedIn) return;

        initializeStore().catch(e => console.error(e));

        await this.$router.push({name: "Buildings"});
    }
}
</script>

<style lang="scss" scoped>
@import "../scss/variables";

.form-control {
    &:focus {
        box-shadow: none;
        border: 1px solid #ced4da;
    }
}

img {
    max-width: 300px;
}

main {
    max-width: $xs;
}
</style>
