
import Vue from "@/extensions/Vue";
import {Options} from "vue-class-component";
import CreateTokenRequest from "@/http/requests/create-token-request";
import {initializeStore, userContext} from "@/store";
import UserGetters from "@/store/modules/user/user-getters";
import UserState from "@/store/modules/user/user-state";

@Options({
    name: "Login",
})
export default class Login extends Vue {
    login = userContext.actions.login;

    username = "";
    password = "";

    get isLoggedIn(): UserGetters["hasSession"] {
        return userContext.getters.hasSession;
    }

    get loginError(): UserState["loginError"] {
        return userContext.state.loginError;
    }

    get userRequestInProgress(): boolean {
        return userContext.state.requestInProgress;
    }

    async onSubmit(): Promise<void> {
        const createTokenRequest = new CreateTokenRequest(this.username, this.password);

        await this.login(createTokenRequest);

        if (!this.isLoggedIn) return;

        initializeStore().catch(e => console.error(e));

        await this.$router.push({name: "Buildings"});
    }
}
