export default class CreateTokenRequest {
    public username: string;
    public password: string;
    public tokenName: string;

    constructor(username: string, password: string) {
        this.username = username;
        this.password = password;
        this.tokenName = "vanity_token";
    }
}